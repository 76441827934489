<template>
    <div class="registerTry-wrap">
        <img class="bg-image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20231208/337b7d9008bb27995ad0feca80f9de42.jpg" mode="aspectFit" />
        <div class="rt-w-title flex-sbc">
            <div class="wt-title flex-wc" @click="clickGoBalck">
                <div class="balck">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="title">注册试用</div>
            </div>
            <div class="wt-logo">
                <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240509/82e742358ab6127565c1bbe95e93c796.png" alt="">
            </div>
        </div>
        <div class="rt-w-tip">
            <div class="title">注册试用</div>
            <div class="tip">欢迎体验有库云进销存，注册将获得15天免费使用资格。</div>
        </div>
        <div class="rt-w-content">
            <div class="wc-item">
                <div class="wc-i-title">账户</div>
                <div class="wc-i-content">
                    <el-input v-model="info.phone" placeholder="请输入注册有库云账户手机号码"></el-input>
                </div>
            </div>
            <div class="wc-item">
                <div class="wc-i-title">验证码</div>
                <div class="wc-i-content flex-sbc">
                    <el-input v-model="info.code" placeholder="请输入验证码"></el-input>
                    <el-button @click="getVerificationCode" :disabled="countdown > 0"
                        type="primary" plain style="margin-left: 15px">
                        {{ countdown > 0 ? countdown + '秒重新获取' : '获取验证码' }}
                    </el-button>
                </div>
            </div>
            <div class="wc-item">
                <div class="wc-i-title">企业所在地</div>
                <div class="wc-i-content flex-sbc">
                    <div class="titel">省</div>
                    <el-select @change="changeProvince" v-model="info.province" placeholder="选择省" style="width: 240px">
                        <el-option
                            v-for="item in provinceArr"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                    <div class="titel">市</div>
                    <el-select @change="changeCity" v-model="info.city" placeholder="选择市" style="width: 240px">
                        <el-option
                            v-for="item in cityArr"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                    <div class="titel">区</div>
                    <el-select v-model="info.area" placeholder="选择区" style="width: 240px">
                        <el-option
                            v-for="item in areaArr"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="rt-w-tag">注册后，首次登录通过验证码登录后进行账号密码设置</div>
        <div class="rt-w-code flex-wc">
            <div class="title">推荐码</div>
            <div class="content">
                <el-input v-model="info.invitation_code" size="large" placeholder="非必填"></el-input>
            </div>
        </div>
        <div class="rt-w-btn">
            <div class="btn flex-cc">确定</div>
        </div>
    </div>
</template>

<script>
import areaObj from '@/utils/area.json';
export default {
    data () {
        return {
            info: {
                phone: '',
                province: '',
                city: '',
                area: '',
                code: '',
                invitation_code: ''
            },
            provinceArr: [],
            cityArr: [],
            areaArr: [],
            countdown: 0
        }
    },
    mounted () {
        this.provinceArr = Object.keys(areaObj)
    },
    methods: {
        // 免费试用提交
        clickRegisterSubmit(){
             // 发送获取验证码的请求
             let params = {
                platform: 2,
                phone: this.info.phone,
                code: this.info.code,
                invitation_code: this.info.invitation_code,
                province: this.info.province,
                city: this.info.city,
                district: this.info.area,
            }
            api.post('/api/login/register_trial', params, (res) => {
                this.$message.success('注册试用成功')
                this.$router.go(-1) 
            });
        },
        clickGoBalck(){
            this.$router.go(-1) 
        },
        // 获取验证码
        getVerificationCode(){
           this.info.code = ''
            if(this.info.phone == ''){
                this.$message.error('请输入注册手机号码')
                return
            }
            if (this.countdown > 0) {
                return; 
            }
            // 发送获取验证码的请求
            api.get('/api/login/get_register_vif', {phone: this.info.phone}, (res) => {
                this.countdown = 60;
                this.$message.success(res.msg)
                const timer = setInterval(() => {
                    this.countdown--;
                    if (this.countdown <= 0) {
                        clearInterval(timer); 
                    }
                }, 1000);
            });
        },
        // 选择省
        changeProvince(){
            this.cityArr = Object.keys(areaObj[this.info.province])
            this.info.city = ''
            this.info.area = ''
        },
        // 选择市
        changeCity(){
            console.log();
            this.areaArr = areaObj[this.info.province][this.info.city]
            this.info.area = ''
        },
    },
}
</script>

<style lang='scss'>
.registerTry-wrap{
    height: 100vh;
    position: relative;
    .bg-image{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        vertical-align: baseline;
        z-index: -1;
    }
    .rt-w-title{
        height: 50px;
        background: #fff;
        padding: 0 20px;
        .wt-title{
            .balck{
                .el-icon-arrow-left{
                    font-size: 16px;
                    color: #101010;
                    font-weight: bold;
                }
            }
            .title{
                font-weight: bold;
                font-size: 16px;
                color: #101010;
                margin-left: 10px;
                
            }
        }
        .wt-logo{
            .logo{
                width: 70px;
                height: 15px;
            }
        }
    }
    .rt-w-tip{
        padding: 30px 20px 20px 20px;
        .title{
            font-size: 36px;
            color: #101010;
            font-weight: bold;
        }
        .tip{
            font-size: 14px;
            color: #101001;
            line-height: 24px;
            padding-top: 10px;
            padding-right: 70px;
        }
    }
    .rt-w-content{
        padding: 20px;
        .wc-item{
            padding-bottom: 20px;
            .wc-i-title{
                font-size: 16px;
                color: #101010;
                font-weight: bold;
                padding-bottom: 20px;
            }
            .wc-i-content{
                .titel{
                    padding-right: 10px;
                    padding-left: 10px;
                }
                .titel:first-child{
                    padding-left: 0;
                }
                .el-input__inner{
                    height: 46px;
                }
                .el-button{
                    height: 46px;
                }
            }
        }
    }
    .rt-w-tag{
        font-size: 14px;
        color: #999;
        padding: 0 20px;
    }
    .rt-w-code{
        padding: 30px 20px;
        .title{
            font-size: 16px;
            color: #101010;
            font-weight: bold;
            margin-right: 20px;
        }
        .el-input__inner{
            height: 46px;
        }
    }
    .rt-w-btn{
        padding: 20px;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        .btn{
            width: 100%;
            background: #0068ff;
            color: #fff;
            font-size: 16px;
            height: 46px;
            border-radius: 4px;
        }
    }
}
</style>
